@import "styles/_theme-vektklubb.scss";
@import "~@styles/utils/mixins/breakpoints";
@import "~@styles/utils/mixins/typography";

.container {
  background-color: $color-web-background-secondary;
  position: relative;
  margin-top: auto;
  border-top: 0.2rem $color-core-white solid;

  &.withBackground {
    background-image: url("./footer_bg.png");
    background-size: cover;
    background-position: top left;
  }
}

.content {
  padding: 4rem 1.5rem;
  position: relative;

  @include breakpoint-larger-up {
    width: 1170px;
    margin: 0 auto;
    padding: 6rem 0 3rem;
  }
}

.interactiveContent {
  @include breakpoint-larger-up {
    display: flex;
    flex-direction: row;
  }

  &.oneRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.mainLinksWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;

  @include breakpoint-medium-up {
    display: inline-flex;
    width: 50%;
  }

  @include breakpoint-larger-up {
    display: flex;
    width: 25%;
  }

  .oneRow & {
    width: 50%;

    @include breakpoint-larger-up {
      display: flex;
      width: 25%;
    }
  }
}

.mainList {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.mainTitle {
  @include h4;

  font-weight: normal;
  color: $color-text-secondary;
}

.mainLink {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.8rem;
  margin: 0 0 0.8rem;
  color: $color-text-primary;
  display: inline-block;
}

.subLinksWrapper {
  @include breakpoint-medium-up {
    display: inline-flex;
    flex-direction: column;
    width: 50%;
  }

  @include breakpoint-larger-up {
    display: flex;
    flex-direction: row;
  }

  .oneRow & {
    width: 50%;
  }
}

.section {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;

  @include breakpoint-medium-up {
    width: 100%;
  }
}

.subList {
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    line-height: 2.1rem;
  }
}

.sectionTitle {
  @include h5;

  color: $color-text-secondary;
  font-size: 1.2rem;
}

.subLink {
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: $color-text-primary;
  display: inline-block;
}

.noLink {
  color: $color-text-secondary;
  margin: 0;
}

.socialContainer {
  margin-bottom: 4rem;
  width: 100%;

  @include breakpoint-larger-up {
    width: 25%;
  }
}

.socialDisclaimer {
  text-align: center;
  font-size: 1.2rem;
  line-height: 2.1rem;
  margin-top: 0.5rem;
  color: $color-text-secondary;

  @include breakpoint-larger-up {
    text-align: left;
  }
}

.logo {
  display: block;
  text-align: center;
  height: 6rem;
  margin-bottom: 1rem;

  img {
    max-width: 100%;
    height: 100%;
  }

  @include breakpoint-larger-up {
    text-align: left;
  }
}

.iconsWrapper {
  display: flex;
  justify-content: center;

  @include breakpoint-larger-up {
    justify-content: left;
  }
}

.iconLink {
  display: inline-flex;
  margin: 0 1rem;

  @include breakpoint-larger-up {
    margin: 0 1rem 0 0;
  }
}

.icon {
  display: block;
  height: 5.5rem;
  width: 5.5rem;
  fill: $color-web-footer-social-network-logo-default;
}

.publisher,
.newspaper {
  font-size: 1.2rem;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 1rem;
  color: $color-text-secondary;
}

.newspaper {
  color: $color-text-secondary;
}

@media print {
  .container {
    display: none;
  }
}
